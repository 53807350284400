
import './App.css';

function App() {
  return (
    <div className="bg-red-500">
     tailwind
    </div>
  );
}

export default App;
